import React from 'react';
import {
  PLAYER_LOOP_MAX_DURATION,
  PLAYER_PLAYBACKRATE_MAX,
  PLAYER_PLAYBACKRATE_MIN,
  PLAYER_PLAYBACKRATE_STEP,
  PLAYER_SEEK_STEP_BIG,
  PLAYER_SEEK_STEP_SMALL,
  PLAYER_VOLUME_MAX,
  PLAYER_VOLUME_MIN,
  PLAYER_VOLUME_STEP
} from '../../Constants';
import { clamp, getDurationString } from '../../Utils/Common';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import TdLibController from '../../Controllers/TdLibController';

// this file is to split Player.js into multiple files
// for readability

export default class PlayerMethods extends React.Component {

  static getBufferedTime = (time, buffered) => {
    if (!buffered || !buffered.length) {
      return 0;
    }

    for (let i = 0; i < buffered.length; i++) {
      const start = buffered.start(i);
      const end = buffered.end(i);
      if (start <= time && time < end) {
        return end;
      }
    }

    return 0;
  }

  onKeyDown = event => {
    const { key, code, altKey, ctrlKey, metaKey, shiftKey } = event;

    const video = this.videoRef.current;
    if (!video) return;

    let handled = false;
    switch (code) {
      case 'ArrowLeft': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleSeek(video.currentTime - PLAYER_SEEK_STEP_SMALL);
          handled = true;
        }
        break;
      }
      case 'KeyJ': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleSeek(video.currentTime - PLAYER_SEEK_STEP_BIG);
          handled = true;
        }
        break;
      }
      case 'ArrowRight': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleSeek(video.currentTime + PLAYER_SEEK_STEP_SMALL);
          handled = true;
        }
        break;
      }
      case 'KeyL': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleSeek(video.currentTime + PLAYER_SEEK_STEP_BIG);
          handled = true;
        }
        break;
      }
      case 'ArrowUp': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleVolume(video.volume + PLAYER_VOLUME_STEP);
          handled = true;
        }
        break;
      }
      case 'ArrowDown': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleVolume(video.volume - PLAYER_VOLUME_STEP);
          handled = true;
        }
        break;
      }
      case 'Space':
      case 'KeyK': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleClick();
          handled = true;
        }
        break;
      }
      case 'KeyM': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleMute();
          handled = true;
        }
        break;
      }
      case 'KeyF': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleFullScreen();
          handled = true;
        }
        break;
      }
      case 'KeyI': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handlePictureInPicture();
          handled = true;
        }
        break;
      }
      case 'Digit0':
      case 'Digit1':
      case 'Digit2':
      case 'Digit3':
      case 'Digit4':
      case 'Digit5':
      case 'Digit6':
      case 'Digit7':
      case 'Digit8':
      case 'Digit9': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          const progress = new Number(key.replace('Digit', '')) / 10.0;
          this.handleSeekProgress(progress);
          handled = true;
        }
        break;
      }
      case 'Home': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleSeek(0);
          handled = true;
        }
        break;
      }
      case 'End': {
        if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
          this.handleSeek(video.duration - 1.0);
          handled = true;
        }
        break;
      }
      case 'Comma': {
        if (!altKey && !ctrlKey && !metaKey && shiftKey) {
          this.handlePlaybackRate(video.playbackRate - PLAYER_PLAYBACKRATE_STEP);
          handled = true;
        }
        break;
      }
      case 'Period': {
        if (!altKey && !ctrlKey && !metaKey && shiftKey) {
          this.handlePlaybackRate(video.playbackRate + PLAYER_PLAYBACKRATE_STEP);
          handled = true;
        }
        break;
      }
    }

    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleVolume = volume => {
    const video = this.videoRef.current;
    if (!video) return;

    volume = clamp(volume, PLAYER_VOLUME_MIN, PLAYER_VOLUME_MAX);

    video.volume = volume;
    this.showMediaHint(`${Math.round(video.volume * 100)}%`);
  };

  handlePlaybackRate = rate => {
    const video = this.videoRef.current;
    if (!video) return;

    rate = clamp(rate, PLAYER_PLAYBACKRATE_MIN, PLAYER_PLAYBACKRATE_MAX)

    video.playbackRate = rate;
    this.showMediaHint(`${rate}x`);
  };

  handleSeekProgress = progress => {
    const video = this.videoRef.current;
    if (!video) return;

    this.handleSeek(progress * video.duration);
  };

  handleSeek = currentTime => {
    const video = this.videoRef.current;
    if (!video) return;

    currentTime = clamp(currentTime, 0, video.duration || 0);

    video.currentTime = currentTime;
    this.setState({ currentTime });
  };

  onClientUpdateMediaShortcut = update => {
    const { event } = update;
    if (!event) return;

    this.onKeyDown(event);
  };

  load() {
    const video = this.videoRef.current;
    if (!video) return;

    video.load();

    this.setState({
      duration: 0,
      currentTime: 0,
      play: true,
      dragging: false,
      buffered: null,
      waiting: true
    });
  }

  handleClick = () => {
    this.startStopPlayer();
  };

  startStopPlayer = () => {
    const video = this.videoRef.current;
    if (!video) return;

    const { waiting } = this.state;
    if (waiting) {
      this.setState({
        play: !this.state.play,
        hidden: false
      });
    } else {
      if (video.paused) {
        video.play()
      } else {
        video.pause();
      }
    }
  };

  handlePlay = event => {
    const { onPlay } = this.props;

    this.setState({
      play: true,
      hidden: true
    });

    TdLibController.clientUpdate({ '@type': 'clientUpdateMediaViewerPlay' });
    onPlay && onPlay(event);
  };

  handlePause = event => {
    const { onPause } = this.props;

    this.setState({
      play: false,
      hidden: false
    });

    TdLibController.clientUpdate({ '@type': 'clientUpdateMediaViewerPause' });
    onPause && onPause(event);
    this.saveProgress();
  };

  handleLoadedData = () => {
    const video = this.videoRef.current;
    if (!video) return;
  };

  handleMouseDown = event => {
    event.stopPropagation();

    const video = this.videoRef.current;
    if (!video) return;

    this.setState({
      dragging: true,
      draggingTime: video.currentTime
    });
  };

  handleChange = (event, value) => {
    const video = this.videoRef.current;
    if (!video) return;

    this.setState({
      draggingTime: value * video.duration
    });
  };

  handleChangeCommitted = () => {
    const { dragging, draggingTime } = this.state;
    if (!dragging) return;

    this.setState({
      dragging: false,
      currentTime: draggingTime,
      draggingTime: 0
    }, () => {
      const video = this.videoRef.current;
      if (!video) return;

      if (Number.isFinite(draggingTime)) {
        video.currentTime = draggingTime;
      }
    });
  };

  handleFullScreenIOS = event => {
    event && event.stopPropagation();
    const video = this.videoRef.current;
    if (!video) return;
    if (video.webkitEnterFullScreen) {
      video.webkitEnterFullScreen();
    } else if (video.enterFullScreen) {
      video.enterFullScreen();
    }

  }

  handleFullScreen = event => {
    event && event.stopPropagation();

    const root = this.contentRef.current;
    if (!root) return;

    const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
    if (fullscreenElement) {
      this.exitFullscreen();
      return;
    }

    this.requestFullscreen(root);
  };

  requestFullscreen(element) {
    const method = element.requestFullscreen || element.mozRequestFullScreen || element.webkitRequestFullscreen;

    method && method.call(element);
  }

  exitFullscreen() {
    const method = document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen;

    method && method.call(document);
  }

  handleClickRoot = event => {
    event.stopPropagation();

    const { mouseDownRoot } = this;
    if (!mouseDownRoot) return;

    this.mouseDownRoot = false;
    this.startStopPlayer();
  }

  handleMouseDownRoot = event => {
    this.mouseDownRoot = true;
    event.stopPropagation();
  }

  static getVolumeIcon = value => {
    if (value === 0) {
      return <VolumeOffIcon fontSize='small' />;
    }

    if (value < 0.25) {
      return <VolumeMuteIcon fontSize='small' />;
    }

    if (value < 0.5) {
      return <VolumeDownIcon fontSize='small' />;
    }

    return <VolumeUpIcon fontSize='small' />;
  };

  handleVolumeChange = event => {
    const { onVolumeChange } = this.props;

    const video = this.videoRef.current;
    if (!video) return;

    const { volume } = video;

    this.setState({
      volume
    });

    TdLibController.clientUpdate({ '@type': 'clientUpdateMediaVolume', volume });
    onVolumeChange && onVolumeChange(event);
  };

  handleVolumeSliderChange = (event, volume) => {
    if (volume === this.state.volume) return;

    this.setState({
      volume
    }, () => {
      const video = this.videoRef.current;
      if (!video) return;

      video.volume = volume;
    });
  };

  handleVolumeSliderChangeCommitted = event => {
    const video = this.videoRef.current;
    if (!video) return;

    document.activeElement.blur();
  };

  showMediaHint(text) {
    const { fileId } = this.props;

    TdLibController.clientUpdate({
      '@type': 'clientUpdateMediaHint',
      fileId,
      text
    });
  }

  handleMute = () => {
    const video = this.videoRef.current;
    if (!video) return;

    if (video.volume === 0) {
      video.volume = this.prevVolume || 0.5;
    } else {
      this.prevVolume = video.volume;
      video.volume = 0;
    }
  }

  handleWaiting = () => {
    this.setState({ waiting: true });
  };

  handleCanPlay = event => {
    const { target: video } = event;

    this.setState({
      waiting: false
    }, () => {
      if (!video) return;

      const { play } = this.state;
      if (play) {
        video.play();
      } else {
        video.pause();
      }
    });
  };

  handlePictureInPicture = async () => {
    const { fileId } = this.props;
    const { duration, currentTime, volume, play, buffered, waiting } = this.state;

    const video = this.videoRef.current;
    if (!video) return;

    TdLibController.clientUpdate({
      '@type': 'clientUpdatePictureInPicture',
      videoInfo: {
        fileId,
        video,
        duration,
        currentTime,
        volume,
        play,
        buffered,
        waiting
      }
    });

    return;

    if (!video.duration) return;

    const pictureInPictureElement = document.pictureInPictureElement || document.mozPictureInPictureElement || document.webkitPictureInPictureElement;
    if (pictureInPictureElement) {
      this.exitPictureInPicture();
      return;
    }

    try {
      const pipWindow = await this.requestPictureInPicture(video);
      TdLibController.clientUpdate({
        '@type': 'clientUpdateMediaViewerContent',
        content: null
      });
      video.onpause = event => {
        event.target.play();
        event.target.onpause = null;
      };
      video.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
    } catch (error) { }
  };

  handleLeavePictureInPicture = event => {
    const video = this.videoRef.current;

    if (!video) event.target.src = null;
    event.target.removeEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
  };

  async requestPictureInPicture(element) {
    const method = element.requestPictureInPicture || element.mozRequestPictureInPicture || element.webkitRequestPictureInPicture;
    if (!method) return null;

    return method.call(element);
  }

  exitPictureInPicture() {
    const method = document.exitPictureInPicture || document.mozExitPictureInPicture || document.webkitExitPictureInPicture;

    method && method.call(document);
  }

  handleDoubleClick = event => {
    this.handleFullScreen(event);
  };

  handleVideoKeyDown = event => {
    event.preventDefault();
  };

  handlePanelDoubleClick = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  handlePanelEnter = () => {
    this.panelEnter = true;
  };

  handlePanelLeave = () => {
    this.panelEnter = false;
  };

  handleMouseOver = event => {
    const { hidden } = this.state;

    if (hidden) {
      this.setState({
        hidden: false
      });
    }

    clearTimeout(this.mouseOverTimeout);
    this.mouseOverTimeout = setTimeout(() => {
      if (this.panelEnter) return;
      if (!this.state.play) return;

      this.setState({
        hidden: true
      });
    }, 1000);
  };
}