
import vtt from 'vtt-live-edit';
import srtParser2 from 'srt-parser-2'

const HOST = `https://subtitle.pyraplayer.com`;
// const HOST = `http://localhost:15443`;

class SubtitleUtil {

  cleanSubString(subtitleString) {
    let str = subtitleString;
    str = str.replace("Advertise your product or brand here", "::: PyraPlayer :::");
    str = str.replace("contact www.OpenSubtitles.org today", "Have a good time :)"); 
    return str;
  }

  convertSRTtoVTT(srtString) {
    let str = `WEBVTT\n\n`;
    str += srtString.replace(/\,/g, '.');
    return str;
  }

  convertVVTToSRT(subtitleString) {
    let str = subtitleString.replace('WEBVTT', '').trim();
    str = str.replace(/\./g, ',');
    return str;
  }

  async createVTTObjectURLFromFileUploadSub(file) {
    if (file.name.includes('vtt')) {
      const url = URL.createObjectURL(file);
      return url;
    }
    
    const readFileContent = () => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(){
        resolve(reader.result);
      };
      reader.readAsText(file);
    });

    const srtString = await readFileContent();
    const vvtString = this.convertSRTtoVTT(srtString);
    const cleaned = this.cleanSubString(vvtString);
    var vttBlob = new Blob([cleaned], {
      type: 'text/plain'
    });
    const url = URL.createObjectURL(vttBlob);
    return url;
  }

  async searchSubtitle({ title, imdb, season, episode }, lang = 'all') {
    if (!title && !imdb) return [];
    const res = await fetch(HOST + '/search', {
      url: HOST + '/search',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title, imdb, season, episode }),
    });
    const json = await res.json();
    const subs = [];
    for (let langCode in json) {
      // if (!json[langCode].vtt) continue;
      // const vvtRequest = await fetch(json[langCode].vtt);
      // const text = await vvtRequest.text();
      // const cleaned = this.cleanSubString(text);
      // var vttBlob = new Blob([cleaned], {
      //   type: 'text/plain'
      // });
      // const url = URL.createObjectURL(vttBlob);
      subs.push({
        langCode,
        language: json[langCode].lang,
        format: json[langCode].format,
        vvt: json[langCode].vtt,
        gz: json[langCode].utf8,
        filename: json[langCode].filename,
        provider: 'opensubtitles'
      })
    }
    return subs;
  }

  async fetchSubContentToObjectUrl({ vvt, gz, format }) {
    if (!vvt && !gz) return {
      vvtText: undefined,
      vvtUrl: undefined
    };
    let didVVTSuccecced = false;
    let vvtText = '';
    if (vvt) {
      try {
        const vvtRequest = await fetch(vvt);
        if (vvtRequest.status == 200) {
          vvtText = await vvtRequest.text();
          didVVTSuccecced = true;
        }
      } catch(err) {
        console.log('err here', err);
      }
    }
    console.log('didVVTSuccecced', didVVTSuccecced, vvtText);
    if (!didVVTSuccecced) {
      try {
        const gzRequest = await fetch(HOST + '/convert-gz-to-vvt', {
          url: HOST + '/convert-gz-to-vvt',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ gz, format }),
        });
        const gzJSON = await gzRequest.json();
        vvtText = gzJSON.vvtText;
      } catch(err) {
        return {
          vvtText: undefined,
          vvtUrl: undefined
        };;
      }
    }
    const cleaned = this.cleanSubString(vvtText);
    var vttBlob = new Blob([cleaned], {
      type: 'text/plain'
    });
    const objectUrl = URL.createObjectURL(vttBlob);
    return {
      vvtText: vvtText,
      vvtUrl: objectUrl
    };;
  }

  vvtEditInfo = {
    offset: 0
  };
  changeSubPosition(miliseconds, videoId = 'pyraplayer-player') {
    this.vvtEditInfo.offset = miliseconds;
    vtt.addOffset(videoId, miliseconds / 1000); 
    return this.vvtEditInfo;
  }

  resetSubPosition(){
    this.vvtEditInfo.offset = 0;
  }

  handleSyncSubLine = ({ startTime, text}, videoId = 'pyraplayer-player') => {
    const videoElement = document.querySelector('#'+videoId);
    const currentTime = videoElement.currentTime;
    const lineStartTimeInSeconds = (() => {
      const [days, hours, seconds] = startTime.split(':');
      const totalSeconds = Number(days) * 86400 + hours * 24 + Number(seconds.replace(',', '.'));
      return totalSeconds;
    })();
    const delta = currentTime - lineStartTimeInSeconds;
    this.changeSubPosition(delta * 1000, videoId);
  }

  parseSubContentToArray(subtitleString) {
    const isWebVVT = subtitleString.includes('WEBVTT');
    const srtString = isWebVVT ? this.convertVVTToSRT(subtitleString) : subtitleString;
    var parser = new srtParser2();
    var result = parser.fromSrt(srtString);
    /*
    [{
      id: '1',
      startTime: '00:00:11,544',
      endTime: '00:00:12,682',
      text: 'Hello' 
    }]
    */
    return result;
  }
}

export default new SubtitleUtil();