import CaptionParser from './MovieInfo.CaptionParser';
const episodeParser = require('episode-parser');
const TMDB_API_KEY = 'bcbfb84e2ef31b11f360d4846616ff99';

const inMemoryCacheInfo = {};
const inMemotySubCache = {};

class MovieInfo {

  imdbInMemoryDB = {} // for media_album_id
  saveMediaAlbumIdWithIMDB(imdb, media_album_id) {
    this.imdbInMemoryDB[media_album_id] = imdb;
  }
  getIMDBFromMediaAlbumId(media_album_id) {
    return this.imdbInMemoryDB[media_album_id]
  }

  imdbFromMessage(message) {
    const tryCaption = CaptionParser.parseImdbFromCaption(message);
    if (!!tryCaption) return this.getMainIMDB(tryCaption);
    if (!!message.media_album_id) {
      return this.getIMDBFromMediaAlbumId(message.media_album_id) || '';
    }
  }

  parseImdbFromCaption = CaptionParser.parseImdbFromCaption;
  parseTitleAndYearFromCaption = CaptionParser.parseTitleAndYearFromCaption;
  parsePartFromMessage = CaptionParser.parsePartFromMessage;
  parseSeasonAndEpisodeFromTitle = CaptionParser.parseSeasonAndEpisodeFromTitle

  getMovieInfoPosterFromCaption = async (message, forceImdb = '') => {
    const imdbInfo = this.parseImdbFromCaption(message);
    const imdb = !!forceImdb ? forceImdb : !imdbInfo ? '' : this.getMainIMDB(imdbInfo);
    if (!imdb) return '';
    if (inMemoryCacheInfo[imdb]) return `https://www.themoviedb.org/t/p/w300${inMemoryCacheInfo[imdb].poster_path}`;
    const url = `https://api.themoviedb.org/3/find/${imdb}?api_key=${TMDB_API_KEY}&external_source=imdb_id`;
    const res = await fetch(url);
    const json = await res.json();
    if (json.movie_results && json.movie_results.length > 0) {
      inMemoryCacheInfo[imdb] = json.movie_results[0];
      const poster_path = json.movie_results[0].poster_path;
      return `https://www.themoviedb.org/t/p/w300${poster_path}`;
    }
    if (json.tv_results && json.tv_results.length > 0) {
      inMemoryCacheInfo[imdb] = json.tv_results[0];
      const poster_path = json.tv_results[0].poster_path;
      return `https://www.themoviedb.org/t/p/w300${poster_path}`;
    }
  }

  fetchSubtitleContent = async (imdb) => {
    const preferLanguage = localStorage.getItem('preferSubtitleLanguage') || 'en';
    // find cache sub in preferLanguage or english
    let cachedSub = !!inMemotySubCache[preferLanguage] ? inMemotySubCache[preferLanguage][imdb] : '';
    if (!!cachedSub) return cachedSub;
    let textSub = '';
    try {
      const url = `https://files.pyraplayer.com/s/${imdb}${preferLanguage === 'en' ? '' : '.'+preferLanguage}.vtt`;
      const res = await fetch(url);
      textSub = await res.text();
      inMemotySubCache[preferLanguage] = inMemotySubCache[preferLanguage] || {};
      inMemotySubCache[preferLanguage][imdb] = textSub;
    } catch(err) {}

    if (!!textSub || preferLanguage === 'en') return textSub;
    try {
      cachedSub = !!inMemotySubCache.en ? inMemotySubCache.en[imdb] : '';
      if (!!cachedSub) return cachedSub;
      const url = `https://files.pyraplayer.com/s/${imdb}.vtt`;
      const res = await fetch(url);
      textSub = await res.text();
      inMemotySubCache.en = inMemotySubCache.en || {};
      inMemotySubCache.en[imdb] = textSub;
    } catch(err) {}
    return textSub;
  }


  getMainIMDB = (imdb = '') => {
    if (imdb.includes('_')) return imdb.split('_')[0];
    return imdb;
  }
  /*
    {
      [imdb]: {
        [fileName]: percentage: 0 -> 100
      }
    }
  */
  recentProgress = (() => {
    const saved = localStorage.getItem('recentProgress');
    if (!saved) return {};
    try {
      return JSON.parse(saved);
    } catch(err) {
      return {};
    }
  })(); 
  saveRecent(imdb, filename, percentage) {
    this.recentProgress[imdb] = this.recentProgress[imdb] || {};
    this.recentProgress[imdb][filename] = percentage;
    localStorage.setItem('recentProgress', JSON.stringify(this.recentProgress));
  }
  getRecent(imdb, filename) {
    if (!this.recentProgress[imdb]) return 0;
    return this.recentProgress[imdb][filename] || 0;
  }
}

export default new MovieInfo();