const episodeParser = require('episode-parser');

class CaptionParser {

  // 00:20, 01:54:26
  durationToSeconds = (s) => {
    const parts = s.split(':');
    const hours = parts.length === 3 ? parts[0] : 0;
    const minutes = parts.length === 3 ? parts[1] : parts[0];
    const seconds = parts.length === 3 ? parts[2] : parts[1];
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  parsePartFromMessage = (message, caption = '') => {
    const captionText = !!caption ? caption : 
    (!!message && !!message.content && !!message.content.caption && !!message.content.caption.text)
    ? message.content.caption.text
    : '';

    if (!captionText) return [];

    // #parts: 00:00 00:20 The beginning, 01:54:26 01:56:45 Credit
    const tryInline = () => {
      const shouldCatchLineBreak = captionText.includes('\n') || (!!captionText.match(/#/g) && captionText.match(/#/g).length === 1);
      const regPart = shouldCatchLineBreak ? /#parts:((.(?!#parts:))+?).+/g : /#parts:((.(?!#parts:))+?)\s/g;
      const matchPart = (captionText+' ').match(regPart);
      if (!matchPart) return [];
      const parts = matchPart[0].replace('#parts:', '').split(',').map(v => v.trim());
      return parts.map(p => {
        let arr = p.split(' ');
        if (arr.length < 3) return undefined;
        return {
          start: this.durationToSeconds(arr[0]),
          startText: arr[0],
          end: this.durationToSeconds(arr[1]),
          endText: arr[1],
          name: arr.slice(2, arr.length).join(' '),
        }
      }).filter(v => !!v);
    };

    // #parts:
    // 00:00 00:20 The beginning
    // 01:54:26 01:56:45 Credit
    const tryMultipleLines = () => {
      if (!captionText.match(/#parts:\n/g)) return [];
      const lines = captionText.split('\n').map(val => val.trim());
      const partsStringIndex = lines.indexOf('#parts:');
      let i=1;
      let parts = [];
      while (partsStringIndex + i <= lines.length -1 && !lines[partsStringIndex + i].includes('#')) {
        let p = lines[partsStringIndex + i];
        let arr = p.split(' ');
        if (arr.length < 3) {
          i++;
          continue;
        };
        parts.push({
          start: this.durationToSeconds(arr[0]),
          startText: arr[0],
          end: this.durationToSeconds(arr[1]),
          endText: arr[1],
          name: arr.slice(2, arr.length).join(' '),
        });
        i++;
        continue;
      }
      return parts;
    };

    const resInline = tryInline();
    if (resInline.length > 0) return resInline;
    return tryMultipleLines();
  }

  parseSeasonAndEpisodeFromTitle = (text = '', filename = '') => {
    let fromFileName = {};
    if (!!filename) {
      const isSeason = !!filename.match(/S(\d+)E/g)
      const cleaned = filename.replace(/_/g, '.');
      fromFileName = episodeParser(cleaned);
      // console.log('fromFileName', fromFileName);
      if (!!fromFileName) {
        return {
          name: fromFileName.show,
          season: isSeason ? fromFileName.season : 0,
          episode: isSeason ? fromFileName.episode : 0,
          year: '',
        }
      }
    }

    // in case episodeParser does not work
    // example: TorrentCouch_com_Silicon_Valley_S05E01_720p_BRRip_x264_ESubs.mp4


    // console.log('parseSeasonAndEpisodeFromTitle', text, filename);
    // Ms Marvel - S1.E2\n#year: 2022\n#imdb: tt10857164_tt11950110
    // return { name, season, episode }
    const reg = /S\d+.E\d+/g;
    const match = text.match(reg);
    if (!match) return null;
    if (match.length > 1) return null;
    const { title, year } = this.parseTitleAndYearFromCaption(undefined, text);
    console.log({ title, year, text });
    const name = title.replace(' - ' + match[0], '').replace(match[0], '').trim();
    const [seasonText, episodeText] = match[0].split('.');
    
    return {
      name,
      season: Number(seasonText.replace('S', '')),
      episode: Number(episodeText.replace('E', '')),
      year,
    }
  }

  parseTitleAndYearFromCaption(message, caption) {
    
    let title = '';
    let year = 0;

    const captionText = !!caption ? caption : 
      (!!message && !!message.content && !!message.content.caption && !!message.content.caption.text)
      ? message.content.caption.text
      : '';

    if (!captionText) return { title, year };
    if (!captionText.includes('#name')) return { title: captionText, year };

    const shouldCatchLineBreak = captionText.includes('\n') || (!!captionText.match(/#/g) && captionText.match(/#/g).length === 1);
    const regName = shouldCatchLineBreak ? /#name:((.(?!#name:))+?).+/g : /#name:((.(?!#name:))+?)\s/g;
    const matchName = (captionText+' ').match(regName);
    if (!matchName) return { title, year };
    const name = matchName[0].replace('#name:', '').trim();
    title = name;

    const regYear = shouldCatchLineBreak ? /#year:((.(?!#year:))+?).+/g : /#year:((.(?!#year:))+?)\s/g;
    const matchYear = (captionText+' ').match(regYear);
    if (!matchYear) return { title, year };
    year = matchYear[0].replace('#year:', '').trim();

    return {
      title, year,
    }
  }

  parseImdbFromCaption(message) {
    if (!Boolean(!!message && !!message.content && !!message.content.caption && !!message.content.caption.text)) return '';
    const { text } = message.content.caption;
    if (!text) return '';
    const reg = /#imdb:((.(?!#imdb:))+?)\s/g;
    const matchImdb = (text+' ').match(reg);
    if (!matchImdb) return;
    const imdb = matchImdb[0].replace('#imdb:', '').trim();
    return imdb || '';
  }
}

export default new CaptionParser();