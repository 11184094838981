

const PlayerRef = {
  current: undefined,
}

export const makePlayerRef = (t) => {
  PlayerRef.current = t;
};

export default PlayerRef;